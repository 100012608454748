<template>
  <div class="login">
    <div class="login-html">
      <div class="row m-t-md">
        <div class="col-sm-12">
          <input
            id="tab-1"
            checked
            class="sign-in"
            type="radio"
          >
          <label
            class="tab"
            for="tab-1"
          >
            {{ $Amplify.I18n.get("Sign Up") }}
          </label>
          <a
            class="tab"
            href="#"
            @click="signIn"
          >
            {{ $Amplify.I18n.get("Sign In") }}
          </a>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-6">
          <label
            class="label"
            for="username"
          >
            {{ $Amplify.I18n.get("Username") }}
          </label>
          <input
            id="username"
            v-model="username"
            v-validate="'required|alpha_num'"
            :class="errors.has('username') ? 'error' : ''"
            autofocus
            class="input"
            data-vv-as="username"
            name="username"
            type="text"
          >
          <span
            v-show="errors.has('username')"
            class="help text-warning"
          >{{ errors.first('username') }}</span>
        </div>
        <div class="col-sm-6">
          <label
            class="label"
            for="branchId"
          >
            {{ $Amplify.I18n.get("Branch ID") }}
          </label>
          <input
            id="branchId"
            v-model="branchId"
            v-validate="'required|digits:4'"
            :class="errors.has('branchId') ? 'error' : ''"
            class="input"
            data-vv-as="branch ID"
            name="branchId"
            type="text"
          >
          <span
            v-show="errors.has('branchId')"
            class="help text-warning"
          >{{ errors.first('branchId') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-6">
          <label
            class="label"
            for="firstName"
          >
            {{ $Amplify.I18n.get("First Name") }}
          </label>
          <input
            id="firstName"
            v-model="firstName"
            v-validate="'required'"
            :class="errors.has('firstName') ? 'error' : ''"
            class="input"
            data-vv-as="first name"
            name="firstName"
            type="text"
          >
          <span
            v-show="errors.has('firstName')"
            class="help text-warning"
          >{{ errors.first('firstName') }}</span>
        </div>
        <div class="col-sm-6">
          <label
            class="label"
            for="lastName"
          >
            {{ $Amplify.I18n.get("Last Name") }}
          </label>
          <input
            id="lastName"
            v-model="lastName"
            v-validate="'required'"
            :class="errors.has('lastName') ? 'error' : ''"
            class="input"
            data-vv-as="last name"
            name="lastName"
            type="text"
          >
          <span
            v-show="errors.has('lastName')"
            class="help text-warning"
          >{{ errors.first('lastName') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-6">
          <label
            class="label"
            for="email"
          >
            {{ $Amplify.I18n.get("Email") }}
          </label>
          <input
            id="email"
            v-model="email"
            v-validate="'required|email'"
            :class="errors.has('email') ? 'error' : ''"
            class="input"
            data-vv-as="email"
            name="email"
            type="email"
          >
          <span
            v-show="errors.has('email')"
            class="help text-warning"
          >{{ errors.first('email') }}</span>
        </div>
        <div class="col-sm-6">
          <label
            class="label"
            for="phone"
          >
            {{ $Amplify.I18n.get("Phone Number") }}
          </label>
          <input
            id="phone"
            v-model="phone"
            v-validate="{ required: true, regex: /^\+91[\d]{10}$/ }"
            :class="errors.has('phone') ? 'error' : ''"
            class="input"
            data-vv-as="phone"
            name="phone"
            type="text"
          >
          <span
            v-show="errors.has('phone')"
            class="help text-warning"
          >{{ errors.first('phone') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-6">
          <label
            class="label"
            for="password"
          >
            {{ $Amplify.I18n.get("New Password") }}
          </label>
          <input
            id="password"
            ref="password"
            v-model="password"
            v-validate="'required|min:8|verify-password'"
            :class="errors.has('password') ? 'error' : ''"
            class="input"
            data-type="password"
            data-vv-as="password"
            name="password"
            type="password"
          >
          <span
            v-show="errors.has('password')"
            class="help text-warning"
          >{{ errors.first('password') }}</span>
        </div>
        <div class="col-sm-6">
          <label
            class="label"
            for="confirmPassword"
          >
            {{ $Amplify.I18n.get("Confirm Password") }}
          </label>
          <input
            id="confirmPassword"
            v-model="confirmPassword"
            v-validate="'required|confirmed:password'"
            :class="errors.has('confirmPassword') ? 'error' : ''"
            class="input"
            data-type="password"
            data-vv-as=" "
            name="confirmPassword"
            type="password"
            @keyup.enter="signUp"
          >
          <span
            v-show="errors.has('confirmPassword')"
            class="help text-warning"
          >{{ errors.first('confirmPassword') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <button
            :disabled="!username"
            class="button btn btn-info sign-in"
            @click="signUp"
            v-text="$Amplify.I18n.get('Create Account')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {Auth} from 'aws-amplify';
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Validator} from 'vee-validate';

export default {
  name: "SignUp",
  data() {
    return {
      branchId: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "+91",
      password: "",
      confirmPassword: ""
    };
  },
  computed: {
    ...mapGetters([
      "getUsername",
      "getLogger"
    ]),
    username: {
      get: function () {
        return this.getUsername;
      },
      set: function (newValue) {
        this.UPDATE_USERNAME(newValue);
      }
    }
  },
  watch: {
    phone(newValue) {
      if (newValue === '' || newValue === '+' || newValue === '+1' || newValue === '+9') {
        this.phone = '+91';
      } else if (!_.startsWith(newValue, "+91")) {
        if (_.startsWith(newValue, '+9') || _.startsWith(newValue, '+1')) {
          this.phone = '+91' + newValue.substring(2, newValue.length - 1);
        } else if (_.startsWith(newValue, '+')) {
          this.phone = '+91' + newValue.substring(1, newValue.length - 1);
        } else if (_.startsWith(newValue, '91')) {
          this.phone = '+' + newValue;
        } else {
          this.phone = '+91' + newValue;
        }
      }
    }
  },
  beforeMount() {
    Validator.extend('verify-password', {
      getMessage: () => 'Password must contain at least 1 uppercase letter, 1 lowercase letter and 1 number',
      validate: (value) => {
        const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
        return strongRegex.test(value);
      }
    });
  },
  methods: {
    ...mapMutations([
      "UPDATE_USERNAME"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    signUp: function () {
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              $('body').removeClass('loaded');
              let user = {
                username: this.username,
                password: this.password,
                attributes: {
                  given_name: this.firstName,
                  family_name: this.lastName,
                  email: this.email,
                  phone_number: this.phone,
                  "custom:branch": this.branchId
                }
              };

              Auth.signUp(user)
                  .then(data => {
                    this.getLogger.info("sign up success");
                    AmplifyEventBus.$emit("localUser", data.user);

                    if (data.userConfirmed === false) {
                      $('body').addClass('loaded');
                      return AmplifyEventBus.$emit("authState", "confirmSignUp");
                    }
                    $('body').addClass('loaded');
                    return AmplifyEventBus.$emit("authState", "signedOut");
                  })
                  .catch(e => {
                    $('body').addClass('loaded');
                    this.setMessage('v-notify-error', "Error!", e)
                  });
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
            }
          })
          .catch(() => {
            $('body').addClass('loaded');
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
          });
    },
    signIn: function () {
      AmplifyEventBus.$emit("authState", "signedOut");
    }
  }
};
</script>
