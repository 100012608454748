<template>
  <div class="login">
    <div class="login-html">
      <div class="row m-t-md">
        <div class="col-sm-12">
          <input
            id="tab-1"
            checked
            class="sign-in"
            type="radio"
          >
          <label
            class="tab"
            for="tab-1"
          >
            {{ $Amplify.I18n.get("Set Password") }}
          </label>
          <a
            class="tab"
            href="#"
            @click="signIn"
          >{{ $Amplify.I18n.get("Sign In") }}</a>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <label
            class="label"
            for="newPassword"
          >
            {{ $Amplify.I18n.get("New Password") }}
          </label>
          <input
            id="newPassword"
            v-model="password"
            autofocus
            class="input"
            data-type="password"
            type="password"
          >
        </div>
      </div>
      <div
        v-for="attribute in getCognitoUser.challengeParam.requiredAttributes"
        :key="attribute"
        :attribute="attribute"
        class="row m-t-md"
      >
        <div class="col-sm-12">
          <label
            :for="attribute"
            class="label"
          >
            {{ attribute.charAt(0).toUpperCase() + attribute.slice(1) }}
          </label>
          <input
            :id="attribute"
            v-model="requiredAttributes[attribute]"
            class="input"
          >
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-offset-3 col-sm-6">
          <button
            :disabled="!password"
            class="button btn btn-info sign-in"
            @click="change"
            v-text="$Amplify.I18n.get('Submit')"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Auth} from 'aws-amplify';

export default {
  name: "RequireNewPassword",
  data() {
    return {
      user: "",
      password: "",
      requiredAttributes: {}
    };
  },
  computed: {
    ...mapGetters([
      "getLogger",
      "getCognitoUser"
    ]),
    username: {
      get: function () {
        return this.getUsername;
      },
      set: function (newValue) {
        this.UPDATE_USERNAME(newValue);
      }
    }
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    checkContact(user) {
      Auth.verifiedContact(user)
          .then(data => {
            if (!_.isEmpty(data.verified)) {
              return AmplifyEventBus.$emit("authState", "signedIn");
            } else {
              user = Object.assign(user, data);
              return AmplifyEventBus.$emit("authState", "signedIn");
            }
          })
          .catch(e => this.setMessage('v-notify-error', "Error!", e));
    },
    change() {
      Auth.completeNewPassword(
          this.getCognitoUser,
          this.password,
          this.requiredAttributes
      )
          .then(() => {
            if (this.getCognitoUser.challengeName === "SMS_MFA") {
              AmplifyEventBus.$emit("localUser", this.getCognitoUser);
              AmplifyEventBus.$emit("authState", "confirmSignIn");
            } else if (this.getCognitoUser.challengeName === "MFA_SETUP") {
              AmplifyEventBus.$emit("localUser", this.getCognitoUser);
              AmplifyEventBus.$emit("authState", "setMfa");
            } else {
              this.checkContact(this.getCognitoUser);
            }
          })
          .catch(e => this.setMessage('v-notify-error', "Error!", e));
    },
    signIn: function () {
      AmplifyEventBus.$emit("authState", "signedOut");
    }
  }
};
</script>
