<template>
  <div class="login">
    <div class="login-html">
      <div class="row m-t-md">
        <div class="col-sm-12">
          <input
            id="tab-1"
            checked
            class="sign-in"
            type="radio"
          >
          <label
            class="tab"
            for="tab-1"
          >
            {{ $Amplify.I18n.get("Verify") }}
          </label>
          <a
            class="tab"
            href="#"
            @click="signIn"
          >
            {{ $Amplify.I18n.get("Sign In") }}
          </a>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <label
            class="label"
            for="code"
          >
            {{ $Amplify.I18n.get("Code") }}
          </label>
          <input
            id="code"
            v-model="code"
            v-validate="'required'"
            :class="errors.has('code') ? 'error' : ''"
            autofocus
            class="input"
            data-vv-as="code"
            name="code"
          >
          <span
            v-show="errors.has('code')"
            class="help text-warning"
          >{{ errors.first('code') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="foot-lnk col-sm-offset-3 col-sm-6">
          <button
            :disabled="!code"
            class="button btn btn-info sign-in"
            @click="submit"
            v-text="$Amplify.I18n.get('Confirm')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Auth} from 'aws-amplify';

export default {
  name: "ConfirmSignIn",
  props: {
    confirmSignInConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      verifyAttr: "",
      code: ""
    };
  },
  computed: {
    ...mapGetters([
      "getLogger",
      "getCognitoUser"
    ])
  },
  mounted() {
    if (Object.keys(this.getCognitoUser).length === 0) {
      this.setMessage('v-notify-error', "Error!", "Valid user not received.");
    }
  },
  methods: {
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    submit: function () {
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              $('body').removeClass('loaded');
              Auth.confirmSignIn(
                  this.getCognitoUser,
                  this.code,
                  this.getCognitoUser.challengeName
              )
                  .then(() => {
                    this.getLogger.info("confirmSignIn successs");
                    AmplifyEventBus.$emit("authState", "signedIn");
                  })
                  .catch(e => this.setMessage('v-notify-error', "Error!", e));
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
            }
          })
          .catch(() => {
            $('body').addClass('loaded');
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
          });
    },
    signIn: function () {
      AmplifyEventBus.$emit("authState", "signedOut");
    }
  }
};
</script>
