<template>
  <div class="login">
    <div class="login-html">
      <div class="row m-t-md">
        <div class="col-sm-12">
          <input
            id="tab-1"
            checked
            class="sign-in"
            type="radio"
          >
          <label
            class="tab"
            for="tab-1"
          >
            {{ $Amplify.I18n.get("Reset Password") }}
          </label>
          <a
            class="tab"
            href="#"
            @click="signIn"
          >
            {{ $Amplify.I18n.get("Sign In") }}
          </a>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <label
            class="label"
            for="username"
          >
            {{ $Amplify.I18n.get("Username") }}
          </label>
          <input
            id="username"
            v-model="username"
            v-validate="'required'"
            :class="errors.has('username') ? 'error' : ''"
            autofocus
            class="input"
            data-vv-as="username"
            name="username"
            type="text"
          >
          <span
            v-show="errors.has('username')"
            class="help text-warning"
          >{{ errors.first('username') }}</span>
        </div>
      </div>
      <div
        v-if="sent"
        class="row m-t-md"
      >
        <div class="col-sm-12">
          <label
            class="label"
            for="code"
          >
            {{ $Amplify.I18n.get("Code") }}
          </label>
          <input
            id="code"
            v-model="code"
            v-validate="'required'"
            :class="errors.has('code') ? 'error' : ''"
            autofocus
            class="input"
            data-vv-as="code"
            name="code"
          >
          <span
            v-show="errors.has('code')"
            class="help text-warning"
          >{{ errors.first('code') }}</span>
        </div>
      </div>
      <div
        v-if="sent"
        class="row m-t-md"
      >
        <div class="col-sm-6">
          <label
            class="label"
            for="password"
          >
            {{ $Amplify.I18n.get("New Password") }}
          </label>
          <input
            id="password"
            ref="password"
            v-model="password"
            v-validate="'required'"
            :class="errors.has('password') ? 'error' : ''"
            autofocus
            class="input"
            data-type="password"
            data-vv-as="password"
            name="password"
            type="password"
          >
          <span
            v-show="errors.has('password')"
            class="help text-warning"
          >{{ errors.first('password') }}</span>
        </div>
        <div class="col-sm-6">
          <label
            class="label"
            for="confirmPassword"
          >
            {{ $Amplify.I18n.get("Confirm Password") }}
          </label>
          <input
            id="confirmPassword"
            v-model="confirmPassword"
            v-validate="'required|confirmed:password'"
            :class="errors.has('confirmPassword') ? 'error' : ''"
            class="input"
            data-type="password"
            data-vv-as="password"
            name="confirmPassword"
            type="password"
            @keyup.enter="verify"
          >
          <span
            v-show="errors.has('confirmPassword')"
            class="help text-warning"
          >{{ errors.first('confirmPassword') }}</span>
        </div>
      </div>
      <div
        v-if="!sent"
        class="row m-t-md"
      >
        <div class="col-sm-12">
          <button
            :disabled="!username"
            class="button btn btn-info sign-in"
            @click="submit"
            v-text="$Amplify.I18n.get('Send Code')"
          />
        </div>
      </div>
      <div
        v-else
        class="row m-t-md vertical-align"
      >
        <div class="foot-lnk col-sm-5">
          <a
            href="#"
            @click="submit"
          >Resend Code</a>
        </div>
        <div class="col-sm-offset-1 col-sm-6">
          <button
            :disabled="!username"
            class="button btn btn-info sign-in"
            @click="verify"
            v-text="$Amplify.I18n.get('Submit')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Auth} from 'aws-amplify';

export default {
  name: "ForgotPassword",
  data() {
    return {
      code: "",
      password: "",
      confirmPassword: "",
      sent: false,
    };
  },
  computed: {
    ...mapGetters([
      "getUsername",
      "getLogger"
    ]),
    username: {
      get: function () {
        return this.getUsername;
      },
      set: function (newValue) {
        this.UPDATE_USERNAME(newValue);
      }
    }
  },
  methods: {
    ...mapMutations([
      "UPDATE_USERNAME"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    submit: function () {
      if (this.username === '') {
        this.setMessage('v-notify-error', "Error!", 'No username entered!');
        return;
      }

      Auth.forgotPassword(this.username)
          .then(() => {
            this.sent = true;
            this.getLogger.info("forgotPassword success");
          })
          .catch(e => this.setMessage('v-notify-error', "Error!", e));
    },
    verify: function () {
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              Auth.forgotPasswordSubmit(
                  this.username,
                  this.code,
                  this.password
              )
                  .then(() => {
                    this.getLogger.info("forgotPasswordSubmit success");
                    AmplifyEventBus.$emit("authState", "signedOut");
                  })
                  .catch(e => this.setMessage('v-notify-error', "Error!", e));
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
            }
          })
          .catch(() => {
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
          });
    },
    signIn: function () {
      AmplifyEventBus.$emit("authState", "signedOut");
    }
  }
};
</script>
