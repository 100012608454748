<template>
  <div>
    <SignIn v-if="displayMap.showSignIn" />
    <SignUp v-if="displayMap.showSignUp" />
    <ConfirmSignUp v-if="displayMap.showConfirmSignUp" />
    <ConfirmSignIn v-if="displayMap.showConfirmSignIn" />
    <ForgotPassword v-if="displayMap.showForgotPassword" />
    <RequireNewPassword v-if="displayMap.requireNewPassword" />
    <SetMFA v-if="displayMap.showMfa" />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import GetUser from "aws-amplify-vue/src/services/getUser";
import ConfirmSignIn from "./_components/ConfirmSignIn";
import ConfirmSignUp from "./_components/ConfirmSignUp";
import ForgotPassword from "./_components/ForgotPassword";
import RequireNewPassword from "./_components/RequireNewPassword";
import SetMFA from "./_components/SetMFA";
import SignIn from "./_components/SignIn";
import SignUp from "./_components/SignUp";

export default {
  name: "Authenticator",
  components: {
    ConfirmSignIn,
    ConfirmSignUp,
    ForgotPassword,
    RequireNewPassword,
    SetMFA,
    SignIn,
    SignUp
  },
  data() {
    return {
      displayMap: {}
    };
  },
  computed: {
    ...mapGetters([
      "getLogger"
    ])
  },
  async mounted() {
    AmplifyEventBus.$on("localUser", user => {
      this.UPDATE_COGNITO_USER(user);
    });
    AmplifyEventBus.$on("authState", data => {
      this.displayMap = this.updateDisplayMap(data);
    });
    GetUser(this.$Amplify)
        .then(val => {
          if (val instanceof Error) {
            return (this.displayMap = this.updateDisplayMap("signedOut"));
          }
          this.UPDATE_COGNITO_USER(val);
          return (this.displayMap = this.updateDisplayMap("signedIn"));
        })
        .catch(e => this.setMessage('v-notify-error', "Error!", e));
  },
  methods: {
    ...mapMutations([
      "UPDATE_COGNITO_USER"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    updateDisplayMap: state => {
      return {
        showSignIn: state === "signedOut",
        showSignUp: state === "signUp",
        showConfirmSignUp: state === "confirmSignUp",
        showConfirmSignIn: state === "confirmSignIn",
        showForgotPassword: state === "forgotPassword",
        showSignOut: state === "signedIn",
        showMfa: state === "setMfa",
        requireNewPassword: state === "requireNewPassword"
      };
    }
  }
};
</script>
