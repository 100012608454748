<template>
  <div class="login">
    <div class="login-html">
      <div class="row m-t-md">
        <div class="col-sm-12">
          <input
            id="tab-1"
            checked
            class="sign-in"
            type="radio"
          >
          <label
            class="tab"
            for="tab-1"
          >
            {{ $Amplify.I18n.get("Verify") }}
          </label>
          <a
            class="tab"
            href="#"
            @click="signIn"
          >
            {{ $Amplify.I18n.get("Sign In") }}
          </a>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <label
            class="label"
            for="username"
          >
            {{ $Amplify.I18n.get("Username") }}
          </label>
          <input
            id="username"
            v-model="username"
            v-validate="'required'"
            :class="errors.has('username') ? 'error' : ''"
            autofocus
            class="input"
            data-vv-as="username"
            name="username"
            type="text"
          >
          <span
            v-show="errors.has('username')"
            class="help text-warning"
          >{{ errors.first('username') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <label
            class="label"
            for="code"
          >
            {{ $Amplify.I18n.get("Confirmation Code") }}
          </label>
          <input
            id="code"
            v-model="code"
            v-validate="'required'"
            :class="errors.has('code') ? 'error' : ''"
            autofocus
            class="input"
            data-vv-as="code"
            name="code"
          >
          <span
            v-show="errors.has('code')"
            class="help text-warning"
          >{{ errors.first('code') }}</span>
        </div>
      </div>
      <div
        class="row vertical-align"
        style="margin-top: 100px;"
      >
        <div class="foot-lnk col-sm-5">
          <a
            href="#"
            @click="resend"
          >{{ $Amplify.I18n.get("Resend Code") }}</a>
        </div>
        <div class="col-sm-offset-1 col-sm-6">
          <button
            class="button btn btn-info sign-in"
            @click="confirm"
            v-text="$Amplify.I18n.get('Confirm')"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Auth} from 'aws-amplify';

export default {
  name: "ConfirmSignUp",
  data() {
    return {
      code: ""
    };
  },
  computed: {
    ...mapGetters([
      "getUsername",
      "getLogger"
    ]),
    username: {
      get: function () {
        return this.getUsername;
      },
      set: function (newValue) {
        this.UPDATE_USERNAME(newValue);
      }
    }
  },
  methods: {
    ...mapMutations([
      "UPDATE_USERNAME"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    confirm() {
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              $('body').removeClass('loaded');
              Auth.confirmSignUp(this.username, this.code)
                  .then(() => {
                    this.getLogger.info("confirmSignUp success");
                    this.$Msg
                        .alert(
                            "Your account has been verified. You will now be redirected to the sign-in page.",
                            {position: "top-center", title: "Congratulations!"}
                        )
                        .then(() => {
                          $('body').addClass('loaded');
                          AmplifyEventBus.$emit("authState", "signedOut");
                        });
                  })
                  .catch(e => {
                    $('body').addClass('loaded');
                    this.setMessage('v-notify-error', "Error!", e)
                  });
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
            }
          })
          .catch(() => {
            $('body').addClass('loaded');
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
          });
    },
    resend() {
      Auth.resendSignUp(this.username)
          .then(() => {
            this.getLogger.info("resendSignUp success");
          })
          .catch(e => this.setMessage('v-notify-error', "Error!", e));
    },
    signIn() {
      AmplifyEventBus.$emit("authState", "signedOut");
    }
  }
};
</script>
