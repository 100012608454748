<template>
  <Authenticator v-if="!getSignedIn" />
</template>

<script>
import {mapGetters} from "vuex";
import Authenticator from "../modules/authentication/Authenticator";

export default {
  name: "Home",
  components: {
    Authenticator,
  },
  computed: {
    ...mapGetters([
      "getSignedIn"
    ])
  },
  beforeMount() {
    this.$store.watch(
        (state, getters) => getters.getSignedIn,
        (newValue) => {
          if (newValue) {
            this.$router.push({name: 'dashboard'});
          }
        })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.getSignedIn) {
        next("/dashboard");
      }
    })
  }
};
</script>

<style scoped/>
