<template>
  <div class="login">
    <div class="login-html">
      <div class="row m-t-md">
        <div class="col-sm-12">
          <input
            id="tab-1"
            checked
            class="sign-in"
            type="radio"
          >
          <label
            class="tab"
            for="tab-1"
          >
            {{ $Amplify.I18n.get("Sign In") }}
          </label>
          <a
            class="tab"
            href="#"
            @click="signUp"
          >
            {{ $Amplify.I18n.get("Sign Up") }}
          </a>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <label
            class="label"
            for="username"
          >
            {{ $Amplify.I18n.get("Username") }}
          </label>
          <input
            id="username"
            v-model="username"
            v-validate="'required'"
            :class="errors.has('username') ? 'error' : ''"
            autofocus
            class="input"
            data-vv-as="username"
            name="username"
            type="text"
            @keyup.enter="signIn"
          >
          <span
            v-show="errors.has('username')"
            class="help text-warning"
          >{{ errors.first('username') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <label
            class="label"
            for="password"
          >
            {{ $Amplify.I18n.get("Password") }}
          </label>
          <input
            id="password"
            v-model="password"
            v-validate="'required'"
            :class="errors.has('password') ? 'error' : ''"
            class="input"
            data-type="password"
            data-vv-as="password"
            name="password"
            type="password"
            @keyup.enter="signIn"
          >
          <span
            v-show="errors.has('password')"
            class="help text-warning"
          >{{ errors.first('password') }}</span>
        </div>
      </div>
      <div class="row m-t-md">
        <div class="col-sm-12">
          <button
            class="button btn btn-primary sign-in"
            @click="signIn"
            v-text="$Amplify.I18n.get('Sign In')"
          />
        </div>
      </div>
      <div class="hr" />
      <div class="row m-t-md">
        <div class="foot-lnk col-sm-12">
          <a
            href="#"
            @click="forgot"
          >{{ $Amplify.I18n.get("Forgot Password") }}
            ?</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Auth} from 'aws-amplify';

export default {
  name: "SignIn",
  data() {
    return {
      password: ""
    };
  },
  computed: {
    ...mapGetters([
      "getUsername",
      "getLogger"
    ]),
    username: {
      get: function () {
        return this.getUsername;
      },
      set: function (newValue) {
        this.UPDATE_USERNAME(newValue);
      }
    }
  },
  methods: {
    ...mapMutations([
      "UPDATE_USERNAME"
    ]),
    setMessage: function (type, title, message, timeout = 6000) {
      let content = this.$Amplify.I18n.get(message.message || message);

      if (type === 'alert' || type === 'confirm') {
        return this.$Msg.add(content, {
          type: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      } else {
        this.$Msg.add(content, {
          theme: type,
          position: "top-center",
          timeout: timeout,
          title: title
        });
      }
    },
    signIn: function () {
      this.$validator.validateAll()
          .then((result) => {
            if (result) {
              $('body').removeClass('loaded');
              Auth.signIn(this.username, this.password)
                  .then(data => {
                    this.getLogger.info("sign in success");
                    if (
                        data.challengeName === "SMS_MFA" ||
                        data.challengeName === "SOFTWARE_TOKEN_MFA"
                    ) {
                      $('body').addClass('loaded');
                      AmplifyEventBus.$emit("localUser", data);
                      return AmplifyEventBus.$emit("authState", "confirmSignIn");
                    } else if (data.challengeName === "NEW_PASSWORD_REQUIRED") {
                      $('body').addClass('loaded');
                      AmplifyEventBus.$emit("localUser", data);
                      return AmplifyEventBus.$emit("authState", "requireNewPassword");
                    } else if (data.challengeName === "MFA_SETUP") {
                      $('body').addClass('loaded');
                      AmplifyEventBus.$emit("localUser", data);
                      return AmplifyEventBus.$emit("authState", "setMfa");
                    } else {
                      return AmplifyEventBus.$emit("authState", "signedIn");
                    }
                  })
                  .catch(e => {
                    if (e.code === "UserNotConfirmedException") {
                      $('body').addClass('loaded');
                      return AmplifyEventBus.$emit("authState", "confirmSignUp");
                    } else {
                      $('body').addClass('loaded');
                      this.setMessage('v-notify-error', "Error!", e);
                    }
                  });
            } else {
              let firstErrorInput = $('input.error:first')[0];
              if (firstErrorInput) {
                firstErrorInput.scrollIntoView({behavior: 'instant'});
                firstErrorInput.focus();
              }
            }
          })
          .catch(() => {
            $('body').addClass('loaded');
            let firstErrorInput = $('input.error:first')[0];
            if (firstErrorInput) {
              firstErrorInput.scrollIntoView({behavior: 'instant'});
              firstErrorInput.focus();
            }
          });
    },
    forgot: function () {
      AmplifyEventBus.$emit("authState", "forgotPassword");
    },
    signUp: function () {
      AmplifyEventBus.$emit("authState", "signUp");
    }
  }
};
</script>
