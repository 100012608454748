<template>
  <div :class="amplifyUI.formSection">
    <div
      v-if="!displayTotpSetup"
      :class="amplifyUI.sectionHeader"
    >
      {{ options.header }}
      <div style="font-size: 16px; color: #828282; margin-top: 10px;">
        {{ options.mfaDescription }}
      </div>
    </div>
    <div
      v-if="displayTotpSetup"
      :class="amplifyUI.sectionHeader"
    >
      {{ $Amplify.I18n.get("Verify Authentication Token") }}
      <div style="font-size: 16px; color: #828282; margin-top: 10px;">
        {{ options.tokenInstructions }}
      </div>
    </div>
    <div
      v-if="!displayTotpSetup"
      :class="amplifyUI.sectionBody"
    >
      <div
        v-if="options.mfaTypes.includes('SMS')"
        :class="amplifyUI.formField"
      >
        <div :class="amplifyUI.inputLabel">
          <input
            v-model="mfaPreference"
            :class="amplifyUI.radio"
            name="mfaPreference"
            type="radio"
            value="SMS"
          >
          {{ options.smsDescription }}
        </div>
      </div>
      <div
        v-if="options.mfaTypes.includes('TOTP')"
        :class="amplifyUI.formField"
      >
        <div :class="amplifyUI.inputLabel">
          <input
            v-model="mfaPreference"
            :class="amplifyUI.radio"
            name="mfaPreference"
            type="radio"
            value="TOTP"
          >
          {{ options.totpDescription }}
        </div>
      </div>
      <div
        v-if="options.mfaTypes.includes('None')"
        :class="amplifyUI.formField"
      >
        <div :class="amplifyUI.inputLabel">
          <input
            v-model="mfaPreference"
            :class="amplifyUI.radio"
            name="mfaPreference"
            type="radio"
            value="NOMFA"
          >
          {{ options.noMfaDescription }}
        </div>
      </div>
    </div>
    <div
      v-if="displayTotpSetup"
      :class="amplifyUI.sectionBody"
    >
      <qrcode-vue
        :class="amplifyUI.totpQrcode"
        :size="300"
        :value="token"
        level="H"
      />
      <div :class="amplifyUI.formField">
        <div :class="amplifyUI.inputLabel">
          {{ $Amplify.I18n.get("Verification Code") }} *
        </div>
        <input
          v-model="code"
          :class="amplifyUI.input"
          :placeholder="$Amplify.I18n.get('Verification Code')"
          autofocus
        >
      </div>
    </div>
    <div :class="amplifyUI.sectionFooter">
      <span :class="amplifyUI.sectionFooterPrimaryContent">
        <button
          v-if="!displayTotpSetup"
          id="setMfa"
          :class="amplifyUI.button"
          @click="setMFA"
        >
          {{ $Amplify.I18n.get("Set MFA") }}
        </button>
        <button
          v-if="displayTotpSetup"
          id="verify"
          :class="amplifyUI.button"
          @click="verifyTotpToken"
        >
          {{ $Amplify.I18n.get("Verify Token") }}
        </button>
      </span>
      <span :class="amplifyUI.sectionFooterSecondaryContent">
        <a
          :class="amplifyUI.a"
          @click="cancel"
        >{{
          $Amplify.I18n.get("Cancel")
        }}</a>
      </span>
    </div>
    <div
      v-if="error"
      class="error"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
// import Auth from '@aws-amplify/auth';
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import * as AmplifyUI from "@aws-amplify/ui";
import QrcodeVue from "qrcode.vue";

export default {
  name: "SetMfa",
  components: {
    QrcodeVue
  },
  props: {
    mfaConfig: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      user: null,
      mfaPreference: null,
      code: null,
      token: null,
      error: "",
      displayTotpSetup: false,
      amplifyUI: AmplifyUI,
      logger: {}
    };
  },
  computed: {
    options() {
      const defaults = {
        header: "Multifactor Authentication Preference",
        mfaDescription:
            "AWS Multi-Factor Authentication (MFA) adds an extra layer of protection on top of your user name and password.",
        tokenInstructions:
            "Scan the QR Code with your phone camera or authentication app to get the MFA code.",
        smsDescription:
            "SMS text messaging (receive a code on your mobile device)",
        totpDescription:
            "One-time password (use a QR code and MFA app to save a token on your mobile device)",
        noMfaDescription: "Do not enable MFA",
        mfaTypes: []
      };
      return Object.assign(defaults, this.mfaConfig || {});
    }
  },
  watch: {
    mfaPreference(value) {
      if (value === "TOTP") {
        this.setup();
      }
    }
  },
  mounted() {
    this.logger = new this.$Amplify.Logger(this.$options.name);
    this.setUser();
  },
  methods: {
    setup() {
      this.$Amplify.Auth.setupTOTP(this.user)
          .then(data => {
            this.logger.info("setTOTP success");
            this.token =
                "otpauth://totp/AWSCognito:" +
                this.user.username +
                "?secret=" +
                data +
                "&issuer=AWSCognito";
          })
          .catch(e => this.setError(e));
    },
    setUser: async function () {
      await this.$Amplify.Auth.currentAuthenticatedUser()
          .then(user => {
            this.logger.info("currentAuthenticatedUser success");
            if (user) {
              this.user = user;
            } else {
              this.user = null;
            }
            return this.user;
          })
          .catch(e => {
            this.user = null;
            this.setError(e);
            return this.user;
          });
    },
    setMFA: function () {
      this.$Amplify.Auth.setPreferredMFA(this.user, this.mfaPreference)
          .then(() => {
            AmplifyEventBus.$emit("authState", "signedIn");
            this.$destroy();
          })
          .catch(e => {
            if (e.message === "User has not verified software token mfa") {
              return (this.displayTotpSetup = true);
            }
            this.setError(e);
          });
    },
    verifyTotpToken() {
      this.$Amplify.Auth.verifyTotpToken(this.user, this.code)
          .then(() => {
            this.logger.info("verifyTotpToken success");
            this.setMFA();
          })
          .catch(e => this.setError(e));
    },
    setError: function (e) {
      this.error = this.$Amplify.I18n.get(e.message || e);
      this.logger.error(this.error);
    },
    cancel: function () {
      return this.options.cancelHandler ? this.options.cancelHandler() : null;
    }
  }
};
</script>
